import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { CompanyUpdateService } from '../../services/company-update.service';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';
import { LocalizedTextIds } from 'company-finder-common';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { EditablePropertyGroup } from '../../company-update.interface';

@Component({
  selector: 'blue-knight-information-update',
  templateUrl: './blue-knight-information-update.component.html',
  styleUrls: ['./blue-knight-information-update.component.scss'],
})
export class BlueKnightInformationUpdateComponent
  extends UpdateComponentBaseWithEditItems
  implements OnDestroy, AfterViewInit
{
  public constructor(
    dc: DeploymentContext,
    companyService: CompanyService,
    companyUpdateService: CompanyUpdateService
  ) {
    super(dc, companyUpdateService, companyService);
  }

  public ngAfterViewInit(): void {
    this.setAllSubscriptions();
  }

  public ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }

  // This could all  potentially go into the config file (or meatadata?), but
  // I am not yet convinced that is the right call.
  public alignmentProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.BlueKnightInformationAlignment,
    properties: ['companyObjective', 'alignedGoal', 'approachUsecase'],
  };

  public challengeProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.BlueKnightInformationChallenges,
    properties: ['challenges', 'priorities', 'mentorship'],
  };

  public employmentProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.BlueKnightInformationCompanyEmployees,
    properties: [
      'currentTeamSizeRange',
      'compositionAndGrowth',
      'conferencesAndEvents',
    ],
  };

  public trlProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.CompanyDetailsTechnology,
    properties: ['overallTRL', 'alignedTRL'],
    subheader: {
      subheaderId: LocalizedTextIds.BlueKnightInformationOverviewOfTRLs,
      url: {
        target: 'https://www.medicalcountermeasures.gov/trl/integrated-trls/',
        display: 'https://www.medicalcountermeasures.gov/trl/integrated-trls/',
      },
    },
  };

  public propertyGroups: EditablePropertyGroup[] = [
    this.alignmentProperties,
    this.challengeProperties,
    this.employmentProperties,
    this.trlProperties,
  ];
}
