import { ModelBase } from './_model.base';
import { Contact, CoOwnerContact } from './contact';
import { Opportunity } from './opportunity';
import { Location } from './location';
import { LimitedDealOpportunity } from './limitedDealOpportunity';
import { Deal } from './deal';
import { Funding } from './funding';
import { LocalizedTextIds } from '../config/localizedTextIds';
import {
    BlueKnight,
    Metadata,
    UpdateOptions,
    Updateable,
    getBlueKnightFieldsRaw,
    getMetadataRaw,
    getUpdateOptionsRaw,
    getUpdateableFieldsRaw,
} from './decorators';
import {
    ArrayConversions,
    DynamicChoiceKey,
    EditItemType,
    UpdateConversion,
} from '../entity/company-update.interface';

export type CompanyKey = keyof Company | 'contactName' | 'companyContactTitle';

/**
 * @summary Note that a subset of fields are removed server-side based on a user's role, via stripSensitiveFields
 * and other fields may be removed based on feature flags.
 * @description This approach replaced a PublicCompany -> Company inheritance hierarchy that broke down when v5 introduced
 * multiple role profiles that had access to various subsets of Company properties.
 */
export class Company extends ModelBase {
    /** The date upon which a Company is considered to have engaged with JLABS.
     * The basis of a "New Company" determination, and could be a month or so in the future.
     */
    public commencementDate: Date;
    public graduationDate: Date;

    @Metadata({ header: LocalizedTextIds.CompanyDetailsLeadProductStage })
    @Updateable({
        editType: EditItemType.ConstrainedValue,
        dynamicChoiceKey: DynamicChoiceKey.RDStage,
    })
    public currentRdStage: string;

    @Metadata({ header: LocalizedTextIds.Description })
    @Updateable({
        editType: EditItemType.Multiline,
        maxLength: 32000,
        required: true,
    })
    public description: string;

    @Metadata({ header: LocalizedTextIds.BlueKnight })
    public isBlueKnight: boolean;
    public isExcludeFromReporting: boolean;
    public isPublicityAgreementSigned: boolean;
    public isPubliclyOffered: boolean;

    @Metadata({ header: LocalizedTextIds.QFCAwardee })
    public isQfcWinner: boolean;
    public isStealthMode: boolean;
    public locations: Location[];

    @Metadata({ header: LocalizedTextIds.CompanyName })
    @Updateable({
        maxLength: 255,
        showLength: true,
        required: true,
    })
    public name: string;

    @Metadata({ header: LocalizedTextIds.Logo })
    @Updateable({
        editType: EditItemType.Logo,
    })
    public logoBase64: string;
    public logoBase64Hash: string;
    public statuses: string[];

    public opportunityIdPrimary: string;
    public opportunityLastModifiedDate: Date;

    @Metadata({ header: LocalizedTextIds.Indication })
    @Updateable({
        editType: EditItemType.MultiModal,
        updateConversion: UpdateConversion.DelimitedString,
        labelTextId: LocalizedTextIds.IndicationQuestion,
        referenceDataKey: 'indications',
    })
    public primaryIndication: string[];

    @Metadata({ header: LocalizedTextIds.PrimarySector })
    @Updateable({
        editType: EditItemType.ConstrainedValue,
        labelTextId: LocalizedTextIds.PrimarySectorQuestion,
        referenceDataKey: 'sectors',
        required: true,
    })
    public primarySector: string;
    @Metadata({ header: LocalizedTextIds.PrimarySubSector })
    @Updateable({
        editType: EditItemType.MultiModal,
        labelTextId: LocalizedTextIds.PrimarySubsectorQuestion,
        dynamicChoiceKey: DynamicChoiceKey.PrimarySubsector,
        updateConversion: UpdateConversion.DelimitedString,
        preconditionProp: 'primarySector',
        referenceDataKey: 'subsectors',
        required: true,
    })
    public primarySubSector: string[];
    public primarySectorDetail: string;

    @Metadata({ header: LocalizedTextIds.Problem })
    @Updateable({
        editType: EditItemType.Multiline,
        maxLength: 32000,
        required: true,
    })
    public problem: string;

    @Metadata({ header: LocalizedTextIds.SecondarySector })
    @Updateable({
        editType: EditItemType.ConstrainedValue,
        labelTextId: LocalizedTextIds.SecondarySectorQuestion,
        referenceDataKey: 'sectors',
    })
    public secondarySector: string;
    public secondaryIndication: string[];
    @Metadata({ header: LocalizedTextIds.SecondarySubSector })
    @Updateable({
        editType: EditItemType.MultiModal,
        labelTextId: LocalizedTextIds.SecondarySubSectorQuestion,
        dynamicChoiceKey: DynamicChoiceKey.SecondarySubsector,
        updateConversion: UpdateConversion.DelimitedString,
        preconditionProp: 'secondarySector',
        referenceDataKey: 'subsectors',
    })
    public secondarySubSector: string[];
    public secondarySectorDetail: string;

    @Metadata({ header: LocalizedTextIds.Solution })
    @Updateable({
        editType: EditItemType.Multiline,
        maxLength: 32000,
        required: true,
    })
    public solution: string;

    @Metadata({ header: LocalizedTextIds.Tags })
    @Updateable({
        editType: EditItemType.MultiModal,
        dynamicChoiceKey: DynamicChoiceKey.Tags,
        updateConversion: UpdateConversion.DelimitedString,
        subtitleId: LocalizedTextIds.EditItemTaggingIsAGreat,
        promptTextId: LocalizedTextIds.TagsPickerModalBlurbCompanyUpdate,
        delimiter: '_#_',
    })
    public tags: string[];
    public totalSecuredAndContingentAmount: number;
    public wasAcquired: boolean;

    @Metadata({ header: LocalizedTextIds.Website })
    @Updateable({
        maxLength: 255,
        showLength: true,
    })
    public website: string;
    public opportunityOwnerContactId: string;
    public siteHead: Contact;
    public coOwners: CoOwnerContact[];
    public ceoContactId: string;
    public companyContact: Contact;
    public womenLed: boolean;
    public minorityLed: boolean;

    @Metadata({ header: LocalizedTextIds.JnjInformationFirstTimeEntrepreneur })
    @Updateable({
        isJnjConfidentialInfo: true,
        editType: EditItemType.ConstrainedValue,
        updateConversion: UpdateConversion.BooleanToYesNo,
        updateHeaderKey: LocalizedTextIds.CommunityAndDiversityTitle,
    })
    public firstTimeEntrepreneur: boolean;

    @Metadata({ header: LocalizedTextIds.Country })
    @Updateable({
        isJnjConfidentialInfo: true,
        editType: EditItemType.ConstrainedValue,
        referenceDataKey: 'countryForDeiReportingList',
        promptTextId: LocalizedTextIds.JnjInformationWhereIsYourCompany,
        required: true,
    })
    public countryForDeiReporting: string;

    @Metadata({ header: LocalizedTextIds.JnjInformationLeadershipDiversity })
    @Updateable({
        isJnjConfidentialInfo: true,
        editType: EditItemType.MultiChoice,
        dynamicChoiceKey: DynamicChoiceKey.LeadershipDiversity,
        applyDeiCountryPrefix: true,
        footnoteId: LocalizedTextIds.JnjInformationAboriginalPeoplesMeans,
        footnoteCountries: ['Canada'],
        promptTextId: LocalizedTextIds.JnjInformationPleaseCheckCLevel,
        tooltipId: LocalizedTextIds.JnjInformationWeAreAsking,
        preconditionProp: 'countryForDeiReporting',
        updateConversion: UpdateConversion.CountryPrefixedDelimitedString,
    })
    public leadershipDiversity: string[];

    @Metadata({ header: LocalizedTextIds.JnjInformationBoardAdvisor })
    @Updateable({
        isJnjConfidentialInfo: true,
        editType: EditItemType.MultiChoice,
        dynamicChoiceKey: DynamicChoiceKey.BoardAdvisorDiversity,
        applyDeiCountryPrefix: true,
        footnoteId: LocalizedTextIds.JnjInformationAboriginalPeoplesMeans,
        footnoteCountries: ['Canada'],
        promptTextId: LocalizedTextIds.JnjInformationPleaseCheckBoard,
        tooltipId: LocalizedTextIds.JnjInformationWeAreAsking,
        preconditionProp: 'countryForDeiReporting',
        updateConversion: UpdateConversion.CountryPrefixedDelimitedString,
    })
    public boardAdvisorDiversity: string;

    @Metadata({ header: LocalizedTextIds.JnjInformationWomenLedOrg })
    @Updateable({
        isJnjConfidentialInfo: true,
        editType: EditItemType.ConstrainedValue,
        promptTextId: LocalizedTextIds.JnjInformationDoYouHaveAnyCLevel,
        updateConversion: UpdateConversion.BooleanToYesNo,
    })
    public womenLedOrgLeadership: string;

    @Metadata({ header: LocalizedTextIds.JnjInformationWomenLedBoard })
    @Updateable({
        isJnjConfidentialInfo: true,
        editType: EditItemType.ConstrainedValue,
        promptTextId: LocalizedTextIds.JnjInformationDoYouHaveAnyBoard,
        updateConversion: UpdateConversion.BooleanToYesNo,
    })
    public womenLedBoardOfDirectorsOrEquiv: boolean;
    public topTierTeam: boolean;

    public isJPAL?: boolean;

    /** The Public version of the Company may contain a subset of Opportunities for Deal aggregation */
    public opportunities: Opportunity[];
    public limitedDealOpportunities: LimitedDealOpportunity[];

    public companyUpdateUpdatedDate: Date;
    public companyUpdateApproverEmail: string;
    public companyUpdateComments: string;

    @Metadata({
        header: LocalizedTextIds.CompanyDetailsKeyDifferentiation,
    })
    public keyDifferentiation: string;

    @Metadata({ header: LocalizedTextIds.KeyManagement })
    @Updateable({
        isJnjConfidentialInfo: true,
        editType: EditItemType.Multiline,
        maxLength: 32768,
        showLength: false,
    })
    public keyMgmtAndAdvBm: string;
    public priority: string;
    public progressCreatedDate: Date;
    public progressSubject: string;

    @Metadata({ header: LocalizedTextIds.CompanyDetailsRecentUpdate })
    public progressUpdate: string;
    public jpalContactId: string;
    public jpalContact: Contact;
    public deals: Deal[];
    public funding: Funding[];
    public navigatorUrl: string;
    public highestLevelOfFundingSecured = 'Other';

    // BlueKnight/BARDA properties
    /** Referred to as "Alignment" in the UI */
    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.BlueKnightInformationAlignment })
    @Updateable({
        labelTextId: LocalizedTextIds.BlueKnightAlignment1,
        editType: EditItemType.Multiline,
        maxLength: 1000,
        required: true,
    })
    public companyObjective?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.BlueKnightGoal })
    @Updateable({
        labelTextId: LocalizedTextIds.BlueKnightAlignment2,
        editType: EditItemType.MultiChoice,
        referenceDataKey: 'blueKnightGoals',
        updateConversion: UpdateConversion.DelimitedString,
        required: true,
    })
    public alignedGoal?: string[];

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.CompanyDetailsApproach })
    @Updateable({
        labelTextId: LocalizedTextIds.BlueKnightInformationDescribeThe,
        editType: EditItemType.Multiline,
        maxLength: 1000,
        required: true,
    })
    public approachUsecase?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.CompanyDetailsAnticipated })
    @Updateable({
        labelTextId: LocalizedTextIds.BlueKnightInformationCurrentPlans,
        editType: EditItemType.Multiline,
        required: true,
    })
    public entryExitStrategy?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.CompanyDetailsMentorship })
    @Updateable({
        labelTextId: LocalizedTextIds.BlueKnightInformationDescribeMentorship,
        editType: EditItemType.Multiline,
        required: true,
        maxLength: 1000,
    })
    public mentorship?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.CurrentChallenges })
    @Updateable({
        labelTextId: LocalizedTextIds.BlueKnightInformationDescribeChallenges,
        editType: EditItemType.Multiline,
        required: true,
        maxLength: 100,
    })
    public challenges?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.BlueKnightInformationPriorities })
    @Updateable({
        labelTextId: LocalizedTextIds.GoalsAndPrioritiesQuestion,
        editType: EditItemType.Multiline,
        required: true,
        maxLength: 100,
    })
    public priorities?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.BlueKnightInformationCurrentSize })
    @Updateable({
        editType: EditItemType.ConstrainedValue,
        labelTextId: LocalizedTextIds.CurrentTeamSizeQuestion,
        referenceDataKey: 'teamSizeOptions',
        required: true,
    })
    public currentTeamSizeRange?: string;

    @BlueKnight()
    @Metadata({
        header: LocalizedTextIds.BlueKnightInformationCompositionAndGrowthHeader,
    })
    @Updateable({
        labelTextId: LocalizedTextIds.BlueKnightInformationCompositionAndGrowth,
        editType: EditItemType.Multiline,
        maxLength: 500,
    })
    public compositionAndGrowth?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.ConferencesAndEvents })
    @Updateable({
        labelTextId: LocalizedTextIds.ConferencesAndEventsQuestion,
        editType: EditItemType.Multiline,
        required: true,
        maxLength: 1000,
    })
    public conferencesAndEvents?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.BlueKnightCommercialPartnerships })
    @Updateable({
        editType: EditItemType.Multiline,
        required: true,
        maxLength: 300,
    })
    public commercialPartnerships?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.BlueKnightRDPartnerships })
    @Updateable({
        editType: EditItemType.Multiline,
        required: true,
        maxLength: 300,
    })
    public rAndDPartnerships?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.BlueKnightOtherPartnerships })
    @Updateable({
        editType: EditItemType.Multiline,
        required: true,
        maxLength: 300,
    })
    public otherPartnerships?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.OverallTRL })
    @Updateable({
        labelTextId: LocalizedTextIds.OverallTRLQuestion,
        editType: EditItemType.ConstrainedValue,
        referenceDataKey: 'technologyReadinessLevelList',
        required: true,
    })
    public overallTRL?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.AlignedTRL })
    @Updateable({
        labelTextId: LocalizedTextIds.AlignedTRLQuestion,
        editType: EditItemType.ConstrainedValue,
        referenceDataKey: 'technologyReadinessLevelList',
        required: true,
    })
    public alignedTRL?: string;

    @BlueKnight()
    @Metadata({
        header: LocalizedTextIds.CompositionOfMatter,
    })
    @Updateable({
        labelTextId: LocalizedTextIds.CompositionOfMatterQuestion,
        editType: EditItemType.ConstrainedValue,
        required: true,
        updateConversion: UpdateConversion.BooleanToYesNo,
    })
    public compositionOfMatter?: boolean;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.SecuredIP })
    @Updateable({
        labelTextId: LocalizedTextIds.SecuredIPQuestion,
        editType: EditItemType.ConstrainedValue,
        required: true,
        updateConversion: UpdateConversion.BooleanToYesNo,
    })
    public securedIP?: boolean;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.FiledIP })
    @Updateable({
        labelTextId: LocalizedTextIds.FiledIPQuestion,
        editType: EditItemType.ConstrainedValue,
        required: true,
        updateConversion: UpdateConversion.BooleanToYesNo,
    })
    public filedIP?: boolean;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.AdditionalIPDetails })
    @Updateable({
        labelTextId: LocalizedTextIds.AdditionalIPDetailsQuestion,
        editType: EditItemType.Multiline,
    })
    public addtlIPDetails?: string;
    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.FundingStage })
    @Updateable({
        editType: EditItemType.ConstrainedValue,
        labelTextId: LocalizedTextIds.FundingStageQuestion,
        referenceDataKey: 'blueKnightFundingRounds',
        required: true,
    })
    public fundingStage?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.FundingStageDetails })
    @Updateable({
        labelTextId: LocalizedTextIds.PleaseClarify,
        editType: EditItemType.Multiline,
        preconditionProp: 'fundingStage',
        maxLength: 500,
    })
    public fundingStageDetails?: string;

    @BlueKnight()
    @Metadata({
        header: LocalizedTextIds.NonDilutiveFunding,
    })
    @Updateable({
        labelTextId: LocalizedTextIds.NonDilutiveFundingQuestion,
        editType: EditItemType.Currency,
        required: true,
        maxLength: 100,
    })
    public nonDilutiveFunding?: number;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.DilutiveFunding })
    @Updateable({
        labelTextId: LocalizedTextIds.DilutiveFundingQuestion,
        editType: EditItemType.Currency,
        required: true,
        maxLength: 100,
    })
    public dilutiveFunding?: number;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.MajorMilestones })
    @Updateable({
        labelTextId: LocalizedTextIds.MajorMilestonesQuestion,
        editType: EditItemType.Multiline,
        required: true,
    })
    public majorMilestones?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.CurrentStagePharma })
    @Updateable({
        labelTextId: LocalizedTextIds.PharmaStageQuestion,
        editType: EditItemType.ConstrainedValue,
        referenceDataKey: 'blueKnightPharmaStage',
        required: true,
    })
    public currentPharmaStage?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.CurrentStageMedTech })
    @Updateable({
        labelTextId: LocalizedTextIds.MedTechStageQuestion,
        editType: EditItemType.ConstrainedValue,
        referenceDataKey: 'blueKnightMedTechStage',
        required: true,
    })
    public currentMedTechStage?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.AdditionalContext })
    @Updateable({
        labelTextId: LocalizedTextIds.AdditionalContextQuestion,
        editType: EditItemType.Multiline,
        maxLength: 800,
    })
    public rndStageDetails?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.RegStatus })
    @Updateable({
        labelTextId: LocalizedTextIds.RegulatoryApprovalQuestion,
        editType: EditItemType.ConstrainedValue,
        referenceDataKey: 'regulatoryStages',
        required: true,
    })
    public regulatoryStatus?: string;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.AnticipatedCommercialProductYr })
    @Updateable({
        editType: EditItemType.ConstrainedValue,
        referenceDataKey: 'years',
        labelTextId: LocalizedTextIds.AnticipatedCommercialProductYrQuestion,
        required: true,
    })
    public anticipatedCommercialProductYr?: number;

    @BlueKnight()
    @Metadata({ header: LocalizedTextIds.NonLeadRegStatus })
    @Updateable({
        editType: EditItemType.Multiline,
        labelTextId: LocalizedTextIds.NonLeadRegStatusQuestion,
        maxLength: 500,
    })
    public nonLeadRegStatus?: string;
}

export function isBlueKnightProperty(name: CompanyKey): boolean {
    return getBlueKnightFields().includes(name);
}

export function getBlueKnightFields(): CompanyKey[] {
    return getBlueKnightFieldsRaw(Company.prototype);
}

export function getUpdateableFields(): CompanyKey[] {
    return getUpdateableFieldsRaw(Company.prototype);
}

export function getMetadata(propertyKey: CompanyKey): Metadata {
    return getMetadataRaw(propertyKey, Company.prototype) ?? {};
}

export function getUpdateOptions(propertyKey: CompanyKey): UpdateOptions {
    return getUpdateOptionsRaw(propertyKey, Company.prototype) ?? {};
}

export function getKeysByUpdateConversion(
    conversion: UpdateConversion
): CompanyKey[] {
    return getUpdateableFields().filter(
        (field) => getUpdateOptions(field)?.updateConversion === conversion
    );
}

export function getDependentFields(key: CompanyKey): CompanyKey[] {
    return (
        getUpdateableFields().filter(
            (field) => getUpdateOptions(field)?.preconditionProp === key
        ) ?? []
    );
}

export function getFieldsWithConversions(): CompanyKey[] {
    return getUpdateableFields().filter(
        (field) => !!getUpdateOptions(field)?.updateConversion
    );
}

export function getMultiModalFields(): CompanyKey[] {
    return getUpdateableFields().filter(
        (field) => getUpdateOptions(field)?.editType === EditItemType.MultiModal
    );
}

export function splitString(val: string, delimiter: string): string[] {
    if (!val) {
        return [];
    }
    return val.includes(delimiter) ? val.split(delimiter) : [val];
}

export function convertFieldFromDB(
    field: CompanyKey,
    originalValue: string
): unknown {
    const updateOptions = getUpdateOptions(field);

    if (!updateOptions?.updateConversion || !originalValue) {
        return ArrayConversions.includes(updateOptions.updateConversion)
            ? []
            : originalValue;
    }

    switch (updateOptions.updateConversion) {
        case UpdateConversion.DelimitedString:
            return splitString(originalValue, updateOptions.delimiter ?? ';');
        case UpdateConversion.CountryPrefixedDelimitedString:
            const splitValues = splitString(
                originalValue,
                updateOptions.delimiter ?? ';'
            );
            return splitValues.map((val) =>
                val.includes(' - ') ? val.split(' - ')[1] : val
            );
        default:
            return originalValue;
    }
}

export function IsArrayProperty(key: CompanyKey): boolean {
    return ArrayConversions.includes(getUpdateOptions(key).updateConversion);
}
